import api from './base'

export const createCommande = async (data,token) => {
	const res = await api.post('/commandes', data,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const sendSMS=async(data,token) =>{
	const res=await api.post('commandes/sendSMS',data,{
		headers: {
			Authorization: `Bearer `+token
		  },
	})
	return res.data
}
export const sendSMSMiseADispo=async(data,token)=>{
	const res=await api.post('commandes/sendSMSMiseADispo',data,{
		headers: {
			Authorization: `Bearer `+token
		  },
	})
	return res.data
}
export const sendSMSMission=async(data,token)=>{
	const res=await api.post('commandes/send-sms-mission',data,{
		headers: {
			Authorization: `Bearer `+token
		  },
	})
	return res.data
}
export const verifyCodeSMS=async(data,token) =>{
	const res=await api.post('commandes/verifyCodeSMS',data,{
		headers: {
			Authorization: `Bearer `+token
		  },
	})
	return res.data  
}
export const verifyCodeSMSMission=async(data,token) =>{
	const res=await api.post('commandes/verfiy-code-sms-mission',data,{
		headers: {
			Authorization: `Bearer `+token
		  },
	})
	return res.data
}
export const verifyCodeSMSMiseADispo=async(data,token) =>{
	const res=await api.post('commandes/verifyCodeSMSMiseADispo',data,{
		headers: {
			Authorization: `Bearer `+token
		  },
	})
	return res.data
}

export const getCommande = async (id,token) => {
	const res = await api.get(`/commandes/${id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const getCommandesbyClientIdByDay = async (clientId,token) => {
	const res = await api.get(`/commandes/client/${clientId}/all/day`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getCommandesbyClientIdByDayById = async (clientId,query,token) => {
	const res = await api.get(`/commandes/forclient/day/${clientId}/${query}/all`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getCommandesbyClientIdByDayByAdresse = async (clientId,query,token) => {
	const res = await api.get(`/commandes/forclient/adressse/${clientId}/all?adresse=${query}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getCommandesbyClientIdByDayByPrice = async (clientId,min,max,token) => {
	const res = await api.get(`/commandes/forclient/price/${clientId}/${min}/${max}/all`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getCommandesbyClientIdByDayByDate = async (clientId,query,token) => {
	const res = await api.get(`/commandes/forclient/date/${clientId}/${query}/all`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}


export const getCommandesTerminerbyTransporter = async (transporterId,page,size,token) => {
	const res = await api.get(`/commandes/transporter/${transporterId}?page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getCommandesAnnuleesbyTransporterId = async (transporterId,page,size,token) => {
	const res = await api.get(`/commandes/fortransporter/annulees/${transporterId}/all?page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const getCommandesByTransporterIdForCompatibilite= async (transporterId,query,token)=>{
	const res = await api.get(`/commandes/fortransporter/compatibilite/${transporterId}/${query}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		})
	return res.data
}
export const getCommandesByTransporterIdByIdForCompatibilite= async (transporterId,query,token)=>{
	const res = await api.get(`/commandes/transporter/compatibilite/${transporterId}/${query}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		})
	return res.data
}
export const getCommandesByTransporterIdByAdresseForCompatibilite= async (transporterId,query,token)=>{
	const res = await api.get(`/commandes/transporter/compatibilite/adresse/${transporterId}/${query}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		})
	return res.data
}
export const getCommandesByTransporterIdByPriceForCompatibilite= async (transporterId,min,max,token)=>{
	const res = await api.get(`/commandes/transporter/compatibilite/price/${transporterId}/${min}/${max}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		})
	return res.data
}
export const getCommandesByTransporterIdByDateForCompatibilite= async (transporterId,query,token)=>{
	const res = await api.get(`/commandes/transporter/compatibilite/date/${transporterId}/${query}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		})
	return res.data
}
export const getAllCommandesByClientId = async (clientId,token,page,size) => {
	const res = await api.get(
		`/commandes/client/${clientId}/all?page=${page}&limit=${size}`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			  }
	)
	return res.data  
}
export const getAllCommandesAnnuléesByClientId = async (clientId,token,page,size) => {
	const res = await api.get(
		`/commandes/forclient/annulees/${clientId}/all?page=${page}&limit=${size}`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			  }
	)
	return res.data  
}

export const getAllCommandesByClientIdForHistorique = async (clientId,token) => {
	const res = await api.get(
		`/commandes/forclient/historique/${clientId}/all?page=1&limit=100`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			  }
	)
	return res.data  
}
export const getAllCommandeByClientForFacturation = async (clientId,query,token) => {
	const res = await api.get(
		`/commandes/client/${clientId}/${query}/all`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			  }
	)
	return res.data  
}
export const getCommandeByClientByDateForFacturation = async (clientId,query,token) => {
	const res = await api.get(
		`/commandes/client/${clientId}/${query}/all/date`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			  }
	)
	return res.data  
}
export const getCommandeByClientByPriceForFacturation = async (clientId,pricemin,pricemax,token) => {
	const res = await api.get(
		`/commandes/client/${clientId}/${pricemin}/${pricemax}/all/price`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			  }
	)
	return res.data  
}
export const getCommandesByClientIDByMonth = async (clientID,token) => {
	const res = await api.get(`/commandes/client/${clientID}/all/month`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const getCommandesByTransporterIDByMonth = async (transporterID,token) => {
	const res = await api.get(`/commandes/transporter/${transporterID}/all/month`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getCommandesByTransporterIDByDay = async (transporterID,token) => {
	const res = await api.get(`/commandes/transporter/${transporterID}/all/day`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getCommandesPaginated = async (page, size,token) => {
	const res = await api.get(`/commandes?page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const getCommandesPaginatedForAdmin = async (page, size,token) => {
	const res = await api.get(`/commandes/foradmin?page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}



export const getCommandesWithTransporter = async (page, size,token) => {
	const res = await api.get(`/commandes/attribuer?page=${page}&limit=${size}&sort=date_transport`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const getCommandesWithAnomalies = async (page, size,token) => {
	const res = await api.get(`/commandes/anomalies?page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const getCommandesPlateForme = async (id,page, size,token) => {
	const res = await api.get(
		`/commandes/plateforme/${id}?page=${page}&limit=${size}`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			  }
	)
	return res.data
}

export const getCommandesForSalon = async (page, size,token) => {
	const res = await api.get(`/commandes/salon?page=${page}&limit=${size}&sort=date_transport`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getCommandeTerminer=async(page, size,token)=>{
	const res=await api.get(`commandes/foradmin/terminer/all?page=${page}&limit=${size}&sort=date_transport`,{
		headers: {
			Authorization: `Bearer `+token
		  },
	})
	return res.data
}
export const getCommandesAnnuleesForAdmin = async (page, size,token) => {
	const res = await api.get(`/commandes/foradmin/annulees/all?page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}


export const acceptCommande = async (commandeID, transporterID,token) => {
	const params = new URLSearchParams()
	params.append('transporterID', transporterID)
	//params.append('salon', false)

	const config = {
		headers: {
			Authorization: `Bearer `+token,
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	}

	const res = await api.patch(`/commandes/${commandeID}`, params, config)

	return res.data
}

export const retirerCommandesSalon = async (commandes,token) => {
	const res = await api.patch('/commandes/retirer-commandes-salon', {
		commandes
	},{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}

export const getCommandesByTransporter = async (_id,token) => {
	const res = await api.get(`/commandes/bytransporter/${_id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const getCommandesForTransporter = async (_id,token,page,size) => {
	const res = await api.get(`/commandes/fortransporter/${_id}?page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  }
	)
	return res.data
}

export const attributionCommandes = async (commandes, chauffeurID,token) => {
	const res = await api.post('/commandes/attribution', {
		commandes,
		chauffeurID,
	},{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}

export const validateAttributionCommandes = async (commandes,token) => {
	const res = await api.post('/commandes/attribution', {
		commandes,
		salon:false,
	},{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
		  return res.data

}
export const attributionCommandesTransporter = async (
	commandes,
	transporterID,
	token
) => {
	const res = await api.post('/commandes/attribution', {
		commandes,
		transporterID,
	},{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
} 

export const attributionMiseAdispoChauffeur = async (
	data,
	token
) => {
	const res = await api.patch('/commandes/attribution-miseAdispo-chauffeur',data,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
} 


export const attributionCommandeForSalon = async (commandes,token) => {
	const res = await api.post('/commandes/salon', {
		commandes,
	},{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}

export const accepterCommande = async (commandeId,transporteurId,token) => {
	const res = await api.patch(`/commandes/accepter/${commandeId}`, {
		transporteurId,
	},{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}

export const getCommandesByChauffeur = async (_id,token,page,size) => {
	const res = await api.get(`/commandes/byChauffeur/${_id}?page=${page}&size=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getCommandesAnnuleeByChauffeur = async (_id,token) => {
	const res = await api.get(`/commandes/byChauffeur/annulees/${_id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getCommandeByCityForChauffeur=async(chauffeurId,query,token,page,size)=>{
	const res = await api.get(`/commandes/byChauffeur/city/${chauffeurId}/${query}/all?page=${page}&size=${size}`  ,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getCommandeHistoriqueByCityForChauffeur=async(chauffeurId,query,token,page,size)=>{
	const res = await api.get(`/commandes/byChauffeur/historique/city/${chauffeurId}/${query}?page=${page}&size=${size}`  ,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getCommandeHistoriqueByDateForChauffeur=async(chauffeurId,query,token,page,size)=>{
	const res = await api.get(`/commandes/byChauffeur/historique/date/${chauffeurId}/${query}?page=${page}&size=${size}`  ,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getCommandeBydateForChauffeur=async(chauffeurId,query,token,page,size)=>{
	const res = await api.get(`/commandes/forChauffeur/date/${chauffeurId}/${query}/all?page=${page}&size=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getCommandeByIdForChauffeur=async(chauffeurId,query,token)=>{
	const res = await api.get(`/commandes/forChauffeur/byid/${chauffeurId}/${query}/all`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const getCommandeHistoriqueByIdForChauffeur=async(chauffeurId,query,token)=>{
	const res = await api.get(`/commandes/byChauffeur/historique/${chauffeurId}/${query}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getCommandesByChauffeurForHistorique = async (_id,token,page,size) => {
	const res = await api.get(`/commandes/byChauffeur/historique/${_id}?page=${page}&size=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const updateMargeCommandes = async (marge,token) => {
	const res = await api.patch(`/commandes/updateMarge/${marge}`,null,{
		headers: {
			  Authorization: `Bearer `+token
			},
		})
	return res.data
}
export const updateRecuCommande = async (commandeID,token) => {
	const res = await api.patch(`/commandes/updateRecu/${commandeID}`,null,{
		headers: {
			  Authorization: `Bearer `+token
			},
		})
	return res.data
}

export const updateCommandeWayPoints = async (missionID, data,token) => {
	const res = await api.patch(`/commandes/${missionID}`, data,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const updateStatutCommandeForChauffeur=async(commandId,statut,token)=>{
 const res=await api.patch(`/commandes/updateStatut/${commandId}/${statut}`,null,{
	headers: {
		  Authorization: `Bearer `+token
		},
	  })
 return res.data
}
export const updateStatutMission=async(missionId,commandeId,newStatus,token)=>{
	const res=await api.patch(`/commandes/update-statut-mission/${missionId}`,{newStatus,commandeId},{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	 return res.data
}
export const updateStatutOnlyCommande = async (commandeID, statut,token) => {
	const params = new URLSearchParams()
	params.append('statut', statut)

	const config = {
		headers: {
			Authorization: `Bearer `+token,
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	}

	const res = await api.patch(`/commandes/${commandeID}`, params, config)

	return res.data
}

export const updateStatutCommande = async (
	commandeID,
	statut,
	transporterID,
	token
) => {
	const params = new URLSearchParams()
	params.append('statut', statut)
	params.append('transporterID', transporterID)

	const config = {
		headers: {
			Authorization: `Bearer `+token,
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	}

	const res = await api.patch(`/commandes/${commandeID}`, params, config)

	return res.data
}

export const deleteCommande = async (id,token) => {
	const res = await api.delete(`/commandes/${id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const softDeleteCommande = async (id,token) => {
	const res = await api.get(`/commandes/softdelet/${id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const softDeleteManyCommandes = async (commandes,token) => {
	const res = await api.post(`/commandes/softdeletMultiple`,{ commandes },{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const deleteManyCommandes = async (commandes,token) => {
	const res = await api.post('/commandes/delete', { commandes },{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const updatePaymentForCommande = async (
	commandeID,
	prix,
	paymentStatus,
	paymentNote,
	token
) => {
	const params = new URLSearchParams()
	params.append('prix', prix)
	params.append('paymentNote', paymentNote)
	params.append('paymentStatus', paymentStatus)

	const config = {
		headers: {
			Authorization: `Bearer `+token,
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	}

	const res = await api.patch(`/commandes/${commandeID}`, params, config)

	return res.data
}

export const updateCommandesMargin = async (
	commandes,
	margin,
	token
) => {
	const res = await api.patch('/commandes/margin', {
		commandes,
		margin,
	},{
		headers: {
			  Authorization: `Bearer `+token
			},
	  }
	)

	return res.data
}

export const cancelCommande = async (idCommande,token) => {
	const res = await api.patch(`commandes/cancel/${idCommande}`,null,{
		headers: {
			  Authorization: `Bearer `+token
			},
	  }
	)

	return res.data
}
export const cancelMission = async (commandeId,missionId,token) => {
	const res = await api.patch(`commandes/cancel-mission/${commandeId}/${missionId}`,null,{
		headers: {
			  Authorization: `Bearer `+token
			},
	  }
	)

	return res.data
}
export const remboursementCommande = async (token,id,price) => {
	const res = await api.patch(`commandes/remboursement/${id}/${price}`,null,{
		headers: {
			  Authorization: `Bearer `+token
			},
	  }
	)

	return res.data
}
export const updateSenderCommande = async (data,token,id) => {
	const res = await api.patch(`commandes/updatesender/${id}`,data,{
		headers: {
			  Authorization: `Bearer `+token
			},
	  }
	)

	return res.data
}
export const refuserCommande = async (token,id) => {
	const res = await api.patch(`commandes/${id}/refuser`,null,{
		headers: {
			  Authorization: `Bearer `+token
			},
	  }
	)

	return res.data
}

export const softDeleteWaypoint=async(token,id)=>{
	const res=await api.delete(`commandes/softdeletewaypoint/${id}`,{
		headers: {
			Authorization: `Bearer `+token
		  },	
	})
	return res.data
}
export const addPackage = async (missionID, data,token) => {
	const res = await api.patch(`/commandes/addPackage/${missionID}`, data,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const checkChauffeur=async(chauffeurID,token,commandes)=>{
	const res=await api.post(`commandes/checkChauffeur/${chauffeurID}`,{commandes},{
		headers: {
			Authorization: `Bearer `+token
		  },
	})
	return res.data
}
export const getCommandesByMonthCommercial=async(id,token)=>{
	const res=await api.get(`commandes/commercial/${id}/all/month`,{
		headers: {
			Authorization: `Bearer `+token
		  },
	})
	return res.data
}
export const getRetrieveFailedPayments=async(token)=>{
	const res=await api.get('commandes/foradmin/retrieveFailedPayments/all',{
		headers: {
			Authorization: `Bearer `+token
		  },
	})
	return res.data
}

export const getAllCommandesByClientIdForFacturation = async (clientId,token,page, size) => {
	const res = await api.get(
		`/commandes/clientforfacturation/${clientId}/all?page=${page}&limit=${size}`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			  }
	)
	return res.data  
}
export const FailedChargeDetails=async (id,token)=>{
	const res = await api.get(
		`/commandes/failedChargeDetails/${id}`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			  }
	)
	return res.data  
}
export const getPayments=async (token)=>{
	const res = await api.get(
		`/commandes/prelevements/all`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			  }
	)
	return res.data  
}
export const getPrelevementForClient=async(id,token)=>{
	const  res=await api.get(`/commandes/forClient/prelevements/${id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const reattributionCommandesTransporteur = async (
	commandes,
	transporterID,
	token
) => {
	const res = await api.post('/commandes/reattribution-transporteur', {
		commandes,
		transporterID,
	},{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}
export const demandeTransfert=async(data,token)=>{
	const res = await api.post('/commandes/demande-transfert', data,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data

}
export const getDemandeTransfertForCommande=async(id,token,page,size)=>{
	const res = await api.get(`/commandes/demande-transfert/${id}/all?page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}
export const getDemandeTransferById=async(id,token)=>{
	const res = await api.get(`/commandes/demande-transfert/${id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}
export const updateDemandeTransfert=async(id,data,token)=>{
	const res = await api.patch(`/commandes/demande-transfert/${id}`, data,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data

}
export const demandeTransfertForMiseADispo=async(data,token)=>{
	const res = await api.post('/commandes/demande-transfert/miseadispo', data,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data

}
export const reattributionCommandesChauffeur=async(commande,transporteurId,chauffeurID,distance,token)=>{
	const res = await api.post('/commandes/reattribution/chauffeur', {
		commande,
		transporteurId,
		chauffeurID,
		distance
	},{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}

export const getHistoriqueChauffeurByCommandeID=async(idCommande,idTransporteur,token,page, size)=>{
	const res = await api.get(`/commandes/historique-chauffeur/${idCommande}/${idTransporteur}/all?page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}
export const getHistoriqueChauffeurByMissionId=async(missionId,transporteurId,token,page, size)=>{
	const res = await api.get(`/commandes/historique-mission-chauffeur/${missionId}/${transporteurId}?page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
} 
export const getHistoriqueTransporteurByCommandeID=async(id,token,page, size)=>{
	const res = await api.get(`/commandes/historique-transporteur/${id}/all?page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}
export const reattributionCommandesMiseADispoChauffeur=async(commande,transporteurId,chauffeurID,jour,heure,token)=>{
	const res = await api.post('/commandes/reattribution/chauffeur', {
		commande,
		transporteurId,
		chauffeurID,
		jour,
		heure
	},{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}
export const reattributionCommandesInitChauffeur=async(commandes,transporteurId,chauffeurID,distance,token)=>{
	const res = await api.post('/commandes/reattribution', {
		commandes,
		transporteurId,
		chauffeurID,
	},{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}
export const updateDemandeTransfertMiseADispo=async(id,data,token)=>{
	const res = await api.patch(`/commandes/demande-transfert/miseadispo/${id}`, data,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data

}
export const getMission=async(id,token)=>{
	const res = await api.get(`/commandes/mission/${id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}
export const getMissionsByCommandeId=async(id,token)=>{
	const res = await api.get(`/commandes/missions/${id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}

export  const getPointsPassageForMission=async(id,token)=>{
	const res = await api.get(`/commandes/points-passage-mission/${id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}

export  const getPointsPassageById=async(id,token)=>{
	const res = await api.get(`/commandes/points-passage/${id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}
export const updateRecipientInfo=async(id,data,token)=>{
	const res = await api.patch(`/commandes/update-recipient/${id}`,data,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}
export const verifierCriteresChauffeur=async(data,token)=>{
	const res = await api.post(`/commandes/verifier-criteres-chauffeur`,data,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}


export const reattributionMissionChauffeur=async(data,token)=>{
	const res = await api.patch('/commandes/reattribution/mission/chauffeur',{data},{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}

export const getCommandeFacture=async(commandeId,token)=>{
	const res = await api.get(`/commandes/facture/${commandeId}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}