import React,{useEffect,useState} from 'react'
import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import {  FormSectionCard} from '../../PasserCommande/PasserCommande.styles'
import { CustomInput } from '../../RegisterTransporter/style';
import { getSingleClient } from '../../../api/clients';
import {toggleSuspendreCommercial,softDeleteCommercial} from '../../../api/commerciaux'
import { useForm } from 'react-hook-form';
import {  useSelector } from 'react-redux'
import { updateClient } from '../../../api/clients';
import { useHistory, useParams } from 'react-router'
import toast from 'react-hot-toast'
import Modal from 'react-modal'
Modal.setAppElement('#root')
function CommercantSingle() {
    const [commercial,setCommercial]=useState(null)
    const [modalIsOpen,setModalIsOpen]=useState(false)
    const [modalIsOpenDelete,setModalIsOpenDelete]=useState(false)
    const [suspendre, setSuspendre] = useState(false)
	const [loading, setLoading] = useState(false)
	const user = useSelector((state) => state.auth.user)
	const [chargementEnCours, setChargementEnCours] = useState(false);
    const { id } = useParams()
    const history = useHistory()
    const token = useSelector((state) => state.auth.token);
    const { handleSubmit,formState: { errors },register,reset } = useForm();
    const onSubmit = async (values) => {
        delete values.password
		delete values._id
		delete values.verified
		delete values.verification
		delete values.updatedAt
		delete values.logo
		delete values.kbis
		delete values.createdAt
		delete values.email
		delete values.suspendre
        delete values.city
        delete values.address
        delete values.phone
        delete values.postalCode
		
		
		
		await updateClient(id, values,token)
		
		if(!modalIsOpen && !modalIsOpenDelete){
            history.goBack()
        }
		 
		
	}
    useEffect(()=>{
        async function fetchCommercial(id){
		  setChargementEnCours(true)
          const data=await getSingleClient(id,token)
          delete data.createdAt
          delete data.epi
          delete data.manutention
          delete data.premium
         
          delete data.status
          delete data.updatedAt
          delete data.vehicules
          delete data.verification
          delete data.verified
          setCommercial(data)
          
          setSuspendre(data.suspendre === true)
          reset(data)
		  setChargementEnCours(false)

        } 
          if(id){
            fetchCommercial(id)
          }
       
        },[id])
  return (
    <LayoutAdmin>
    <div
    style={{
        width: '100%',
        fontFamily: 'Montserrat',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '1rem',
        }}
    >
		{chargementEnCours ? 
		 <div
		 style={{
		   display: 'flex',
		   alignItems: 'center',
		   justifyContent: 'center',
		   backgroundColor: 'white',
		   marginTop:"10rem"
		 }}
		 >
		 <div className='customLoader'></div>	
		 </div>
		 :
        <form
            style={{ width: '65%' }}
            onSubmit={handleSubmit(onSubmit)}
        >
        <FormSectionCard>
        <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div>
                        <p
                            style={{
                                color: 'black',
                                fontWeight: 'bold',
                            }}
                        >
                           Fiche Commercial
                        </p>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        <button
                            onClick={ () => {setModalIsOpen(true) }}
                            style={{
                                border: 'none',
                                background: '#ECC9F7',
                                color: '#AB1CC5',
                                fontSize: 17,
                                fontFamily: 'Montserrat',
                                fontWeight: 'bold',
                                padding: '0.6rem 1rem',
                                borderRadius: 5,
                                cursor: 'pointer',
                            }}
                           
                        >
                            {suspendre ? 'Activer' : 'Suspendre'}

                        </button>
                        {(user.role==="admin" || user.role==="super admin") &&
                        <button
                           onClick={async () => {
                           setModalIsOpenDelete(true)
                        }}
                            style={{
                                border: 'none',
                                background: '#F7CDC9',
                                color: '#C5431C',
                                fontSize: 17,
                                fontFamily: 'Montserrat',
                                fontWeight: 'bold',
                                padding: '0.6rem 1rem',
                                borderRadius: 5,
                                cursor: 'pointer',
                                marginLeft: '0.5rem',
                            }}
                          
                        >
                            Supprimer
                        </button>
                         }
                        <button
                            type='submit'
                            style={{
                                border: 'none',
                                background: '#168EEA',
                                color: '#FFF',
                                fontSize: 17,
                                fontFamily: 'Montserrat',
                                fontWeight: 'bold',
                                padding: '0.6rem 1rem',
                                borderRadius: 5,
                                cursor: 'pointer',
                                marginLeft: '0.5rem',
                            }}
                        >
                            Enregistrer
                        </button>
                        <button
                        onClick={()=>{
							
							history.push(`/admin/commerçants/${id}/clients`)
						}

						}
									style={{
										border: 'none',
										background: '#50F5A9',
										color: '#FFF',
										fontSize: 17,
										fontFamily: 'Montserrat',
										fontWeight: 'bold',
										padding: '0.6rem 1rem',
										borderRadius: 5,
										cursor: 'pointer',
										marginLeft: '0.5rem',
									}}
								>
									 Liste Client
								</button>
                    </div>
        </div>
        </FormSectionCard>  
        <FormSectionCard>
        <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <div
                        style={{
                            width: '50%',
                            display: 'flex',
                            flexDirection: 'column',
                            paddingBottom: '2rem',
                        }}
                    >
                        <span
                            style={{
                                color: '#262626',
                                paddingBottom: '1rem',
                            }}
                        >
                            Nom
                        </span>
                        <CustomInput
						type='text'
                        disabled
                        {...register('last_name', {
                            required: true,
                        })}
										
						/>
                    </div>

                    <div style={{ width: 50 }} />

                    <div
                        style={{
                            width: '50%',
                            display: 'flex',
                            flexDirection: 'column',
                            paddingBottom: '2rem',
                        }}
                    >
                        <span
                            style={{
                                color: '#262626',
                                paddingBottom: '1rem',
                            }}
                        >
                            Prénom
                        </span>
                        <CustomInput
                            disabled
							type='text'
										{...register('first_name', {
											required: 'Veuillez indiquer le prénom.',
											minLength: {
												value: 3,
												message: 'Le prénom doit contenir au moins 3 caractères.',
											  },
											  pattern: {
												value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$/,
												message: 'Le prénom doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
											  },
						})}
						/>
                    </div>
        </div>  
        <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            paddingBottom: '2rem',
                        }}
                    >
                        <span
                            style={{
                                color: '#262626',
                                paddingBottom: '1rem',
                            }}
                        >
                            Adresse email
                        </span>
                        <CustomInput
                            disabled
							type='text'
										{...register('email', {
											required: "Veuillez indiquer l'adresse e-mail.",
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
												message: 'Veuillez entrer une adresse e-mail valide',
											  },
							})}
						/>
                    </div>            
        </div>  
        <div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Rôle
									</span>
									<CustomInput
                                     disabled
							         type='text'
										{...register('role')}
						            />
									
	       
        </div>
        <div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Adresse
								</span>
								<CustomInput
                                    disabled
									type='text'
									{...register('address')}
								/>
								{errors.address && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:"14px" }}>
                                {errors.address.message}
                                 </div>
                                )}
		</div>
        <div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Ville
								</span>
								<CustomInput
                                    disabled
									type='text'
									{...register('city')}
								/>
								{errors.city && (
                  <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                  {errors.city.message}
                  </div>
                )}
		</div>
        <div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Code Postal
								</span>
								<CustomInput
                                    disabled
									type='text'
									{...register('postalCode')}
								/>
							{errors.postalCode && (
                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                {errors.postalCode.message}
                </div>
              )}
		</div>
        <div
									style={{
										width: '100%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Téléphone
									</span>
									<CustomInput
                                        disabled
										type='text'
										{...register('phone')}
									/>
									{errors.phone && (
                      <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                      {errors.phone.message}
                       </div>
                   )}
		</div>
        <div
								style={{
									width: '100%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									
                                Paramétrer une réduction en %
								</span>
								<CustomInput
									type='number'
									min={0}
									max={100}
									{...register('reduction', {})}
								/>
                             
							</div>
        </FormSectionCard>    
      
        </form>
        }
        
    </div>
    <Modal
				isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}

				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>{suspendre ? 'Activer Commercial' : 'Suspendre Commercial'}</div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>{`Êtes-vous sûr de vouloir ${suspendre ? 'Activer' : 'Suspendre'} ce Commercial ?`}</p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					
						onClick={async () => {	
						setLoading(true)
						try {
							const data={suspendre:!suspendre,requesterId:user._id}
							const res=await toggleSuspendreCommercial(
								commercial._id,
								data,
								token
							)
					    toast.success(res.message, {
						style: {
							fontFamily: 'Montserrat',
						},
					})
					setSuspendre(!suspendre)
					setModalIsOpen(false)
					history.goBack()
						} catch (error) {
							toast.error(
								error.response.data.message,
								{
									style: {
										fontFamily: 'Montserrat',
									},
								}
							)
							setModalIsOpen(false)
						}finally {
							setLoading(false)
						}	
						}}
						disabled={loading}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
						}}
					>
					 {loading ? 'Chargement' : 'Confirmer'} 
					</button>
					<button
						onClick={async () => {
							setModalIsOpen(false)	
												
						}}
						style={{
							// padding: '1rem 1.5rem',
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	</Modal>
    <Modal
				isOpen={modalIsOpenDelete}
                onRequestClose={() => setModalIsOpenDelete(false)}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>Supprimer Commercial</div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>Êtes-vous sûr de vouloir supprimer ce commercial ? </p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					
						onClick={async () => {	
							setLoading(true)
							try {
							 const res=await softDeleteCommercial(commercial._id,token)
							 toast.success(res.message, {
								 style: {
									 fontFamily: 'Montserrat',
								 },
							 })
							 setModalIsOpenDelete(false)
							 history.goBack()
							} catch (error) {
							 toast.error(
								 error.response.data.message,
								 {
									 style: {
										 fontFamily: 'Montserrat',
									 },
								 }
							 )
							 setModalIsOpenDelete(false)

							}finally {
							 setLoading(false)
						 }
							
                            }}
						disabled={loading}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
						}}
					>
						 {loading ? 'Chargement' : 'Confirmer'} 
					</button>
					<button
						onClick={async () => {
							setModalIsOpenDelete(false)
												
						}}
						style={{
							// padding: '1rem 1.5rem',
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	</Modal>
    </LayoutAdmin>
  )
}

export default CommercantSingle